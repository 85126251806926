import Macy from "macy/dist/macy";

const initMasonry = () => {
    const masonries = Array.from(document.querySelectorAll(".block-masonry"));

    masonries.forEach((masonry) => {
        const macyEl = masonry.querySelector(".masonry__images");

        const macy = new Macy({
            container: macyEl,
            columns: 1,
            margin: 24,
            mobileFirst: true,
            breakAt: {
                600: {
                    columns: 2,
                    margin: 24,
                },
                1200: {
                    columns: 3,
                    margin: 48,
                },
            },
        });

        macy.runOnImageLoad(() => macy.recalculate(true), true);
    });
};

export default function () {
    initMasonry();
}

import PhotoSwipe from "photoswipe/dist/photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

var items;
var itemClicked;

function openLightbox(items) {
    var pswpElement = document.getElementById("pswp");
    // build items array

    var idx = 0;
    var lightboxes = document.querySelectorAll(".lightbox")
    for ( var i = 0; i < lightboxes.length; i++ ) {
        if (lightboxes[i] == itemClicked) {
            idx = i;
        }
    }
    
    // define options (if needed)
    var options = {
        index: idx,
        showHideOpacity: true,
        shareEl: false,
        getThumbBoundsFn: function(index) {
            var thumbnail = document.querySelectorAll(".lightbox")[index];
            var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
            var rect = thumbnail.getBoundingClientRect();
            return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
        }
    };

    var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();
}

function onClick(e) {
    e.preventDefault();
    itemClicked = e.currentTarget;
    openLightbox(items);
}

export default function() {
    // gallery
    items = [];
    var lightboxes = document.querySelectorAll(".lightbox");

    for ( var i = 0; i < lightboxes.length; i++ ) {
        var item = lightboxes[i];
        var size = item.getAttribute("data-size").split("x");
        var type = item.getAttribute("data-type") || "image";
        
        if( type == "video" ) {
            items.push({
                html: item.getAttribute("data-video"),
            });
        } else {
            items.push({
                src: item.getAttribute("href"),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            });
        }
        item.addEventListener("click", onClick, items);
    }
}
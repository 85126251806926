const header = document.querySelector(".header");

const toggleNav = () => {
    const navToggles = Array.from(document.querySelectorAll(".hamburger"));

    navToggles.forEach((toggle) => {
        toggle.addEventListener("click", (e) => {
            document.body.classList.toggle("nav-open");
        });
    });
};

const scrollHeader = () => {
    if (header) {
        let headerHeight = header.offsetHeight;
        let lastScrollTop = 0;
        let delta = 10;

        window.addEventListener("scroll", () => {
            scroll();
        });

        function scroll() {
            let st = window.scrollY || window.pageYOffset;

            if (Math.abs(lastScrollTop - st) <= delta) return; // Fixes IE11 bug

            if (st > lastScrollTop && st > headerHeight) {
                header.classList.add("header--hidden");
            } else {
                header.classList.remove("header--hidden");
                header.classList.add("header--filled");

                if (st <= headerHeight) {
                    header.classList.remove("header--filled");
                }
            }

            lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        }
    }
};

export default function () {
    toggleNav();
    scrollHeader();
}

import inView from "in-view";

export default function () {
    const socialNavSticky = document.querySelector(".social-nav-sticky");

    if (socialNavSticky) {
        inView(".footer")
            .on("enter", (el) => {
                socialNavSticky.classList.add("social-nav-sticky--hidden");
            })
            .on("exit", (el) => {
                socialNavSticky.classList.remove("social-nav-sticky--hidden");
            });
    }
}

import Swiper, { EffectFade } from "swiper";
import Pagination from "swiper/components/pagination";
import Navigation from "swiper/components/navigation";
import Fade from "swiper/components/effect-fade";
import Autoplay from "swiper/components/autoplay";

Swiper.use([Pagination, Navigation, Fade, Autoplay]);

const initGalleries = () => {
    const galleries = Array.from(document.querySelectorAll(".block-gallery"));

    galleries.forEach((gallery) => {
        const swiperEl = gallery.querySelector(".swiper-container");
        const paginationEl = gallery.querySelector(".swiper-pagination");
        const navigationNextEl = gallery.querySelector(".swiper-button-next");
        const navigationPrevEl = gallery.querySelector(".swiper-button-prev");

        const swiper = new Swiper(swiperEl, {
            loop: true,
            slidesPerView: 1,
            speed: 500,
            pagination: {
                el: paginationEl,
                clickable: true,
            },
            navigation: {
                nextEl: navigationNextEl,
                prevEl: navigationPrevEl,
            },
        });
    });
};

const initHero = () => {
    const galleries = Array.from(document.querySelectorAll(".block-hero"));

    galleries.forEach((gallery) => {
        const swiperEl = gallery.querySelector(".swiper-container.swiper-container--has-slides");

        if (!swiperEl) {
            return;
        }

        const paginationEl = gallery.querySelector(".swiper-pagination");

        const swiper = new Swiper(swiperEl, {
            loop: true,
            slidesPerView: 1,
            speed: 500,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
            },
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            pagination: {
                el: paginationEl,
                clickable: true,
            },
        });
    });
};

export default function () {
    initGalleries();
    initHero();
}

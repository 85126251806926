import "core-js/stable";
import "regenerator-runtime/runtime";
import "../sass/site.scss";
import header from "./header";
import inview from "./inview";
import macy from "./macy";
import swiper from "./swiper";
import AOS from "aos";
import lightbox from "./lightbox";

header();
swiper();
macy();
inview();
lightbox();
AOS.init();

